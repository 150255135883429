<template>
  <div class="block-news-news-setting" v-if="loading">
    <div class="setting-block">
      <div class="setting-block-box">
        <div class="block-box-title">
          <div class="box-title-text">Информация</div>
        </div>
        <v-form ref="formInformation" class="block-box-news">
          <ImageInput v-model="news.photo" label="Обложка новости" />
          <TextInput
            :maxLength="300"
            :max="300"
            v-model="news.title"
            label="Название новости"
            valid
          />
          <TextArea
            :maxLength="4000"
            :max="4000"
            v-model="news.description"
            label="О новости"
          />

          <SelectInput
            label="Топики"
            :items="topics"
            deletable-chips
            :clearable="false"
            :multiple="true"
            item-text="name"
            item-value="id"
            v-model="news.topics"
          >
          </SelectInput>
          <!-- stub -->
          <div class="publication-info">
            <SwitchInput
              icon="mdi-download"
              label="Сделать новость видимой"
              v-model="news.isPublished"
            />
            <SwitchInput
              :style="!news.isSendPush ? 'opacity: 0.7; background: var(--app-light-grey-1)' : ''"
              :model-value="false"
              :disabled="!news.isSendPush"
              icon="mdi-bullhorn"
              label="Отправить пуш при публикации новости"
            />
            <div class="info-text">
              <div class="info-icon">
                <v-icon class="mt-1 ml-1 mr-2" color="var(--app-light-grey-2)" size="20"
                  >mdi-information</v-icon
                >
              </div>
              Отправка пуш-уведомления возможна 1 раз. Для повторной отправки
              необходимо создать новость заново
            </div>
          </div>
          <!-- stub -->
        </v-form>
      </div>
    </div>
    <div class="setting-block">
      <div class="setting-block-box">
        <div class="block-box-title">
          <div class="box-title-text">Данные</div>
        </div>
        <div class="block-box-news">
          <div class="box-news-info">
            <div class="info-title">ID новости</div>
            <div class="info-value">
              {{ news.id }}
            </div>
          </div>
          <div class="box-news-info">
            <div class="info-title">Дата создания</div>
            <div class="info-value">
              {{
                this.$moment(parseInt(news.dateCreated)).format("DD.MM.YYYY")
              }}
            </div>
          </div>
          <div class="box-news-info">
            <div class="info-title">Дата последнего редактирования</div>
            <div class="info-value">
              {{
                this.$moment(parseInt(news.dateUpdated)).format("DD.MM.YYYY")
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImageInput from "@/components/main/inputs/ImageInput";
import TextInput from "@/components/main/inputs/TextInput";
import TextArea from "@/components/main/inputs/TextArea";
import SwitchInput from "@/components/main/inputs/SwitchInput";
import SelectInput from "../../components/main/inputs/SelectInput.vue";

export default {
  components: {
    ImageInput,
    TextInput,
    TextArea,
    SwitchInput,
    SelectInput,
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data: () => ({
    loading: false,
    news: {},
    topics: [],
  }),
  methods: {
    updateItem() {
      this.news = JSON.parse(JSON.stringify(this.item));
      this.loading = true;
    },
    sendItems() {
      if (this.$refs.formInformation.validate()) {
        return this.news;
      }
    },

    async getTopics() {
      await this.$axios
        .get(`${this.$store.getters.apiV7}/topics?${new URLSearchParams({
              page: 1,
              take: 9999,
            })}`,
          {
            headers: {
              authorization: this.$store.getters.adminPanelHeaderAuth,
            },
        })
        .then((res) => {
          this.topics = res.data.data;
        })
        .catch((err) => {
          this.$store.commit("errorSet", true);
          this.$store.commit("errorTextSet", err);
        });
    },
  },

  created() {
    this.updateItem();
    this.getTopics();
  },
  beforeDestroy() {},
  watch: {
    item() {
      this.updateItem();
    },
  },
};
</script>
<style lang="scss" scoped>
.block-news-news-setting {
  background: var(--app-grey-1);
  //   overflow: auto;
  height: 100%;
  padding: 32px 64px;
  display: flex;
  justify-content: space-between;
  .setting-block {
    .setting-block-box {
      .block-box-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .box-title-text {
          font-size: 22px;
          font-weight: 600;
          color: var(--app-black);
        }
      }
      .block-box-news {
        .publication-info {
          & ::v-deep .text-box-text {
            font-weight: 400 !important;
          }
        }
        .info-text {
          color: var(--app-light-grey-2);
          display: flex;
          align-items: flex-start;
          font-size: 14px;
          font-weight: 400;
        }
        .box-items-list-item {
          width: 100%;
          min-height: 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          background: var(--app-white);
          border-radius: 6px;
          margin-bottom: 20px;
          padding: 16px 12px;
          .list-item-text {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: var(--app-black);
          }
          .list-item-icon {
            cursor: pointer;
          }
        }
        .box-news-info {
          margin-bottom: 24px;
          .info-title {
            font-size: 12px;
            text-transform: uppercase;
            color: var(--app-grey-3);
          }
          .info-value {
            font-size: 14px;
            color: var(--app-black);
          }
        }
      }
    }
  }

  .setting-block:first-child {
    width: 60%;
    padding-right: 48px;
  }
  .setting-block:last-child {
    width: 40%;
    padding-left: 48px;
  }
  .dialog-topic-table {
    height: 400px;
  }
}
</style>
